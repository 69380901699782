import { Injectable } from '@angular/core';
import { DenialReasonWrapper } from '@fundo/app/classes/denied-manager.class';
import { OfferCalculationResponse } from '@fundo/app/models/offer-calculation-response';
import { NotificationService } from '@shared/dialogs/dialog-notification/services/notification.service';
import { LeadStatusNumber } from '@shared/enums/lead-status';
import { NavigationService } from '@shared/services/navigation.service';
import { take } from 'rxjs';

import { LeadService } from '../lead.service';
import { OfferCalculationService } from '../offer-calculation.service';

@Injectable({
  providedIn: 'root',
})
export class RedirectionService {
  constructor(
    private readonly leadService: LeadService,
    private readonly offerCalcService: OfferCalculationService,
    private readonly navigationService: NavigationService,
    private readonly notificationService: NotificationService,
  ) {}

  // If no status ID is provided, the function will fetch the current status using the 'GET status' endpoint.
  public redirectAccordingToStatus(
    statusId?: number, 
    data?: OfferCalculationResponse | DenialReasonWrapper,
  ): void {
    if (statusId) {
      this.redirectAccordingToStatusId(statusId, data);
      return;
    }
    this.leadService
      .getStatusWithToken()
      .pipe(take(1))
      .subscribe({
        next: (status) => {
          const statusId = status.id;
          this.redirectAccordingToStatusId(statusId, status as any);
        },
        error: () => {
          this.navigationService.navigateToErrorPage();
        },
      });
  }

  public redirectAccordingToOfferCalcResponse(): void {
    this.offerCalcService
      .getOfferCalculation()
      .pipe(take(1))
      .subscribe({
        next: (data: OfferCalculationResponse) => {
          const statusId = data.id;
          this.redirectAccordingToStatusId(statusId, data, true);
        },
        error: () => {
          this.notificationService.openGeneralErrorDialog();
        },
      });
  }

  private redirectAccordingToStatusId(
    statusId: number,
    data: OfferCalculationResponse | DenialReasonWrapper = {} as any,
    isOfferCalculationResponse = false,
  ) {
    switch (statusId) {
      case LeadStatusNumber.FormRedirect:
      case LeadStatusNumber.ConfirmationInfo:
      case LeadStatusNumber.PartialSSNValidationFailed:
      case LeadStatusNumber.CustomerVerification:
      case LeadStatusNumber.PlaidConnection:
        this.navigationService.navigateToApplyNowForm(data);
        break;
      case LeadStatusNumber.IncomeInfo:
      case LeadStatusNumber.LightningVerification:
      case LeadStatusNumber.EmailConfirmation:
      case LeadStatusNumber.BankVerification:
        this.navigationService.navigateToCustomerInfo();
        break;
      case LeadStatusNumber.BankAccountConfirmation:
        this.navigationService.navigateToAccountConfirmation();
        break;
      case LeadStatusNumber.RevenueVerification:
      case LeadStatusNumber.RevenueVerification_renewals:
        this.navigationService.navigateToRevenueVerification();
        break;
      case LeadStatusNumber.AgreementViewed:
      case LeadStatusNumber.AdditionalInfoPending:
      case LeadStatusNumber.IndustryDetails:
      case LeadStatusNumber.DocumentVerification:
      case LeadStatusNumber.PendingReferences:
        this.navigationService.navigateToAdditionalInfo();
        break;
      case LeadStatusNumber.Completed:
      case LeadStatusNumber.Funded:
        this.navigationService.navigateToCompletePage();
        break;
      case LeadStatusNumber.Denied:
      case LeadStatusNumber.Voided:
      case LeadStatusNumber.Withdrawn:
      case LeadStatusNumber.Withdrawn_renewals:
        this.navigationService.navigateToAppDeniedPage(data, isOfferCalculationResponse);
        break;
      case LeadStatusNumber.ManualOfferCalculation:
        this.navigationService.navigateToManualReviewPage();
        break;
      case LeadStatusNumber.OfferCalculation:
      case LeadStatusNumber.OfferCalculation_renewals:
        this.redirectAccordingToOfferCalcResponse();
        break;
      case LeadStatusNumber.OfferConfirmation:
      case LeadStatusNumber.OfferConfirmation_renewals:
        this.navigationService.navigateToProgramsPage();
        break;
      case LeadStatusNumber.ConfirmPersonalDetails:
        this.navigationService.navigateToPersonalConfirmationDetails();
        break;
      default:
        this.navigationService.navigateToErrorPage();
    }
  }
}
