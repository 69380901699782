export enum DENIED_REASON_RULES {
  IncomeSource,
  LightningVerification,
  BankAccount,
  State,
  Financial,
}

export interface DenialReasonWrapper {
  denialReason: string;
}

const DEFAULT_DENIED_REASON =
  'Based on our review of your application, we are unable to move forward with your request for business funding at this time.';

/**
 * Financial and non financial denied reasons messages and its corresponding equivalents
 */
const DENIED_REASONS_MAPPING: {
  reference: RegExp;
  deniedRule: DENIED_REASON_RULES;
  message: string;
}[] = [
  {
    reference: /^Not qualified due to income source$/g,
    deniedRule: DENIED_REASON_RULES.IncomeSource,
    message:
      `## Thank you for applying for a Fundo Merchant Cash Advance. \n\n We have good news and bad news. The bad news is that we are not able to approve your request at this time because we exclusively provide funds for business use.\n\nThe good news is that we may be able to connect you with a partner who can! Click below to explore other options. If we find a match, you'll be automatically redirected to our partner's website to complete your application.\n\nIf you would like to provide the Fundo team with additional information for reconsideration, email us at [support@fundo.com](mailto:support@fundo.com) or visit our website to speak to a live agent via chat between 9am and 5pm EST, Monday to Friday.\n\nWe appreciate you choosing Fundo for your business needs.
      `,
  },
  {
    reference: /^Customer's Bank Accounts Rule:/g,
    deniedRule: DENIED_REASON_RULES.BankAccount,
    message: 'We are unable to process the bank account you specified.',
  },
  {
    reference: /^State Rule:/g,
    deniedRule: DENIED_REASON_RULES.State,
    message:
      'At this time we are unable to process your application as we do not do business in your state.',
  },
  {
    reference: /^Offer amount is less than minimum allowed$/g,
    deniedRule: DENIED_REASON_RULES.Financial,
    message: '',
  },
  {
    reference: /^Score value is less then minimum allowed$/g,
    deniedRule: DENIED_REASON_RULES.Financial,
    message: '',
  },
  {
    reference: /^No accounts are approved$/g,
    deniedRule: DENIED_REASON_RULES.Financial,
    message: '',
  },
  {
    reference: /^Number of Transaction is less then minimum allowed$/g,
    deniedRule: DENIED_REASON_RULES.Financial,
    message: '',
  },
  {
    reference: /^Total Remit value is higher than maximum allowed$/g,
    deniedRule: DENIED_REASON_RULES.Financial,
    message: '',
  },
  {
    reference: /^AMR value is less than minimum allowed$/g,
    deniedRule: DENIED_REASON_RULES.Financial,
    message: '',
  },
];

export class DeniedManager {
  constructor() {}

  /**
   * Whether an application is NonFinancial type given a denialReason
   * @param denialReason
   * @returns
   */
  isNonFinancialDeniedApplication(denialReason: string): boolean {
    const rule = DENIED_REASONS_MAPPING.find((element) => denialReason.match(element.reference))
      ?.deniedRule;
    return (
      !!rule &&
      [
        DENIED_REASON_RULES.IncomeSource,
        DENIED_REASON_RULES.BankAccount,
        DENIED_REASON_RULES.State,
      ].includes(rule)
    );
  }

  /**
   * Whether an application is Financial type given a denialReason
   * @param denialReason
   * @returns
   */
  isFinancialDeniedApplication(denialReason: string): boolean {
    return (
      DENIED_REASONS_MAPPING.find((element) => denialReason.match(element.reference))
        ?.deniedRule === DENIED_REASON_RULES.Financial
    );
  }

  /**
   * Get the rule associated to the denialReason provided
   * @param denialReason
   * @returns
   */
  isDeniedByRule(rule: DENIED_REASON_RULES, denialReason: string = ''): boolean {
    return (
      DENIED_REASONS_MAPPING.find((element) => denialReason.match(element.reference))
        ?.deniedRule === rule
    );
  }

  /**
   * Get the message associated to the denialReason provided
   * @param denialReason
   * @returns the associated message or default message
   */
  getDenialReasonAssociatedMessage(denialReason: string): string {
    return (
      DENIED_REASONS_MAPPING.find((element) => denialReason.match(element.reference))?.message ||
      DEFAULT_DENIED_REASON
    );
  }
}
